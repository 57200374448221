import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
declare var $: any;
@Injectable()
export class LoadingService {
    public show(ref: any) {
        $(ref).addClass('loading').loader('show', {
            overlay: true
        });
        $('#blur-container').addClass('add-blur');
        $('body').addClass('noscroll');
    }
    public hide(ref: any) {
        if ($(ref).hasClass('loading')) {
            $(ref).removeClass('loading').loader('hide');
            $('#blur-container').removeClass('add-blur');
            $('body').removeClass('noscroll');

        }
    }

    start(text: any, gif: any, timeout: any, timeout_func: any) {
        if (text === undefined) { text = ''; }
        // if (gif === undefined || gif === true) { gif = '<i class=\'fa fa-spinner fa-spin\'></i>'; }
        if (gif === undefined || gif === true) { gif = '<img src="./assets/load.png">'; }
        // if ($(".dpui-loading").size() > 0) this.stop();
        let html = '<div class=\'dpui-loading\'><div class=\'dpui-loading-inner\'>';
        if (gif) { html += '<div class=\'dpui-loading-gif\'>' + gif + '</div>'; }
        html += '<div class=\'dpui-loading-text\'>' + text + '</div></div></div>';
        $('body').addClass('noscroll').append(html);

        if (timeout) {
            if (timeout_func === undefined) { timeout_func = function () { }; }
            $('.dpui-loading')[0].timeoutid = window.setTimeout(function () {
                $('.dpui-loading').remove();
                $('body').removeClass('noscroll');
                $('#blur-container').removeClass('add-blur');
                timeout_func();
            }, timeout);
        }
    }
    stop() {
        // if ($(".dpui-loading")[0].timeoutid) window.clearTimeout($(".dpui-loading")[0].timeoutid);
        $('.dpui-loading').remove();
        $('body').removeClass('noscroll');
    }
}
