import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { V1AuthGuard } from '../util/V1AuthGuard';


export const routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'v1/inicio',
        pathMatch: 'full',
        canActivate: [V1AuthGuard]
      },
      {
        path: 'home',
        loadChildren: './home/home.module#HomeModule',
        canActivate: [V1AuthGuard]
      },

      {
        path: 'v1',
        loadChildren: './v1/v1.module#V1Module',
        canActivate: [V1AuthGuard]
      },
      {
        path: 'v1',
        loadChildren: './termos-de-uso/termo-uso.module#TermoUsoModule',
        canActivate: [V1AuthGuard]
      },
      {
        path: 'v1',
        loadChildren: './permissao-empresa/permissao-empresa.module#PermissaoEmpresaModule',
        canActivate: [V1AuthGuard]
      },
      {
        path: 'v1',
        loadChildren: './grupo-recurso/grupo-recurso.module#GrupoRecursoModule',
        canActivate: [V1AuthGuard]
      },
      {
        path: 'v1',
        loadChildren: './parametrizacao-grupo/parametrizacao-grupo.module#ParametrizacaoGrupoModule',
        canActivate: [V1AuthGuard]
      }, {

        path: 'v1',
        loadChildren: './grupo-informacao/grupo-informacao.module#GrupoInformacaoModule',
        canActivate: [V1AuthGuard]
      }
      , {

        path: 'v1',
        loadChildren: './promotor/promotor.module#PromotorModule',
        canActivate: [V1AuthGuard]
      }
      , {

        path: 'v1',
        loadChildren: './cupom-promotor/cupom-promotor.module#CupomPromotorModule',
        canActivate: [V1AuthGuard]
      }



    ]
  },

  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent },

  // { path: 'mapalocalizacao', component: MapaLocalizacaoComponent },

  // Not found
  { path: '**', redirectTo: 'inicio' }
];

