import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgxSelectModule } from 'ngx-select-ex';
import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgDatepickerModule } from 'ng2-datepicker';

registerLocaleData(localeBr, 'pt');
defineLocale('pt', ptBrLocale);

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  return new TranslateHttpLoader(http, './', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    NgxSelectModule,
    Ng4LoadingSpinnerModule.forRoot(),
    SharedModule.forRoot(),
    DatepickerModule,
    BsDatepickerModule.forRoot(),
    RoutesModule,
    NgDatepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [BsDatepickerModule, DatepickerModule, NgDatepickerModule],
  providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
