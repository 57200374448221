
import './modernizr.js'; // 'npm run modernizr' to create this file

import '../node_modules/ika.jvectormap/jquery-jvectormap-1.2.2.min.js';
import '../node_modules/ika.jvectormap/jquery-jvectormap-world-mill-en.js';
import '../node_modules/ika.jvectormap/jquery-jvectormap-us-mill-en.js';

import '../node_modules/jquery-slimscroll/jquery.slimscroll.js';

import '../node_modules/codemirror/lib/codemirror.js';
import '../node_modules/codemirror/mode/javascript/javascript.js';
import '../node_modules/codemirror/mode/xml/xml.js';
import '../node_modules/codemirror/mode/htmlmixed/htmlmixed.js';
import '../node_modules/codemirror/mode/css/css.js';
import './assets/loader/jquery.loader.js';
import './assets/loader/dpLoading-2.x.js';
//import './assets/markers/markerclusterer.js';
//import './assets/marker-animate-master/jquery_easing.js';
//import './assets/marker-animate-master/markerAnimate.js';
