import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../../util/http-error-handler.service';
import { debug } from 'util';

const API_URL = environment.apiUrl;

@Injectable()
export class CentroDeCustoService {
    private handleError: HandleError;
    menuItems: Array<any>;

    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
        this.menuItems = [];
        this.handleError = httpErrorHandler.createHandleError('CentroDeCustoService');
    }
    obterTodos(param: any): Observable<any> {
        const params = new HttpParams()
            .set('pageNumber', `${param.offset}`)
            .set('pageSize', `${param.limit}`)
            .set('descricao', `${param.descricao}`)
            .set('ativo', `${param.ativo}`);

        return this.http.get<any>(API_URL + "centrocusto/ObterTodos", { params })
            .pipe(
                catchError(this.handleError('obterTodos', []))
            );
    }
    obterCentroDeCustoPorEmpresa(param: any): Observable<any> {
        const params = new HttpParams()
            .set('descricao', `${param}`);
        return this.http.get<any>(API_URL + 'centrocusto/ObterCentroDeCustoPorEmpresa', { params })
            .pipe(
                catchError(this.handleError('obterTodos', []))
            );
    }
    obterTodosAtivos(param: any): Observable<any> {
        const params = new HttpParams()
            .set('pageNumber', `${param.offset}`)
            .set('pageSize', `${param.limit}`)
            .set('descricao', `${param.descricao}`)
            .set('ativo', `${param.ativo}`);
        return this.http.get<any>(API_URL + 'centrocusto/ObterTodosAtivos', { params })
            .pipe(
                catchError(this.handleError('ObterTodosAtivos', []))
            );
    }
    obterPorCodigo(id: number): Observable<any> {
        return this.http.get<any>(API_URL + 'centrocusto/ObterPorCodigo/' + id)
            .pipe(
                catchError(this.handleError('obterPorCodigo', []))
            );
    }
    validarCentroCusto(id: number): Observable<any> {
        return this.http.get<any>(API_URL + 'centrocusto/ValidarCentroCusto/' + id)
            .pipe(
                catchError(this.handleError('ValidarCentroCusto', []))
            );
    }
    obterTodasEmpresasAtivas(descricao: string, trazInativos: boolean): Observable<any> {
        const params = new HttpParams()
            .set('trazInativos', `${trazInativos}`)
            .set('descricao', `${descricao}`);
        return this.http.get<any>(API_URL + 'centrocusto/ObterEmpresasAtivas/',{params})
            .pipe(
                catchError(this.handleError('obterTodos', []))
            );
    }
    inserirOuAtualizar(centroCusto: any): Observable<any> {
        return this.http.post<any>(API_URL + 'centrocusto/', centroCusto)
            .pipe(
                catchError(this.handleError('inserirOuAtualizar', centroCusto))
            );
    }
    ativarOuInativar(ids: any): Observable<any> {
        return this.http.post<any>(API_URL + 'centrocusto/AtivarOuInativar/', ids)
            .pipe(
                catchError(this.handleError('ativarOuInativar', ids))
            );
    }
    listarUsuarios(descricao: string): Observable<any> {
        return this.http.get<any>(API_URL + "centrocusto/ListarUsuarios/descricao=" + descricao)
            .pipe(
                catchError(this.handleError('listarUsuarios', []))
            );
    }
    obterUsuariosPorCentroCusto(param: any, codigosCentroDeCusto: any): Observable<any> {
        const params = new HttpParams()
            .set('pageNumber', `${param.offset}`)
            .set('pageSize', `${param.limit}`)
            .set('descricao', `${param.descricao}`)
            .set('ativo', `${param.ativo}`)
            .set('codigosCentroDeCusto', `${codigosCentroDeCusto}`);

        return this.http.get<any>(API_URL + "centrocusto/ObterTodosUsuariosPorCentroDeCusto", { params })
            .pipe(
                catchError(this.handleError('obterTodosUsuariosPorCentrocusto', []))
            );
    }
    ativarOuInativarCentroCustoAssociado(ids: any): Observable<any> {
        return this.http.post<any>(API_URL + "centrocusto/AtivarOuInativarUsuarioAssociado/", ids)
            .pipe(
                catchError(this.handleError('ativarOuInativarUsuarioAssociado', ids))
            );
    }
    associarUsuario(codigoUsuario: any, codigosCentroDeCusto: any): Observable<any> {
        const params = new HttpParams()
            .set('codigoUsuario', `${codigoUsuario}`)
            .set('codigosCentroDeCusto', `${codigosCentroDeCusto}`);

        return this.http.get<any>(API_URL + "centrocusto/AssociarUsuario/", { params })
            .pipe(
                catchError(this.handleError('associarUsuario', []))
            );
    }

    obterExcel(param: any): Observable<any> {
        const params = new HttpParams()
            .set('pageNumber', `${param.offset}`)
            .set('pageSize', `${param.limit}`)
            .set('descricao', `${param.descricao}`)
            .set('ativo', `${param.ativo}`);

        return this.http.get<any>(API_URL + "centrocusto/GerarRelatorioExcel", { params })
            .pipe(
                catchError(this.handleError('obterExcel', 'obterExcel'))
            );
    }

    obterPdf(param: any): Observable<any> {
        const params = new HttpParams()
            .set('pageNumber', `${param.offset}`)
            .set('pageSize', `${param.limit}`)
            .set('descricao', `${param.descricao}`)
            .set('ativo', `${param.ativo}`);

        return this.http.get<any>(API_URL + "centrocusto/GerarRelatorioPdf", { params })
            .pipe(
                catchError(this.handleError('obterPdf', 'obterPdf'))
            );
    }
}
