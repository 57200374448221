import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  HttpErrorHandler,
  HandleError
} from '../../util/http-error-handler.service';
import { AuthorizationData } from '../../models/authorizationData';
import { ResultViewModel } from '../../models/resultViewModel';
const API_URL = environment.apiUrl;
const API_URL_BASE = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class AuthorizationService {
  private handleError: HandleError;
  menuItems: Array<any>;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.menuItems = [];
    this.handleError = httpErrorHandler.createHandleError(
      'AuthorizationService'
    );
  }

  LoginBackOffice(
    login: string,
    senha: string
  ): Observable<ResultViewModel | any> {
    const auth = {
      login: login,
      senha: senha
    };
    return this.http
      .post<ResultViewModel>(
        API_URL_BASE + 'authenticate',
        auth,
        httpOptions
      )
      .pipe(catchError(this.handleError('LoginBackOffice', auth)));
  }

  public SaveLocalAuthorizationData(authorizationData: AuthorizationData) {
    localStorage.setItem(
      'authorizationData',
      JSON.stringify(authorizationData)
    );
  }

  public GetLocalAuthorizationData(): any {
    return JSON.parse(
      localStorage.getItem('authorizationData')
    ) as AuthorizationData;
  }

  public LogoutBackOffice() {
    localStorage.removeItem('authorizationData');
  }

  public GetLocalUser() {
    return this.http.get<any>(API_URL + "Usuarios/ObterUsuarioLogado")
      .pipe(
        catchError(this.handleError('obterTodos', []))
      );
  }

  public RecuperaEmail(email: string) {
    let param = {
      login: email,
      origem: "BACKOFFICE"
    }
    return this.http.post<any>(API_URL_BASE + "Usuarios/EnviarSenha",  param)
      .pipe(
        catchError(this.handleError('obterTodos', []))
      );
  }
}
