import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { menu } from './menu';
import { routes } from './routes';
import { NgxSelectModule } from 'ngx-select-ex';
import {
  NoopAnimationsModule,
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';





@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    SharedModule,
    PagesModule,
    NgxSpinnerModule,
    NgxSelectModule,
    NoopAnimationsModule,
    BrowserAnimationsModule
  ],
  declarations: [],
  exports: [
    RouterModule,
    NgxSelectModule,
    NgxSpinnerModule,
    NoopAnimationsModule,
    BrowserAnimationsModule
  ]
})
export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
    menuService.addMenu(menu);
  }
}
