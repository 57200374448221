// #####################
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// #####################

// #####################
// Ajuda para publicação com package.json:
// Desenvolvimento Local      > npm run build       >>  environment.ts
// Publicação Desenvolvimento > npm run build:dev   >>  environment.dev.ts
// Publicação Pré-produção    > npm run build:stag  >>  environment.stag.ts
// Publicação Produção        > npm run build:prod  >>  environment.prod.ts
// #####################

export const environment = {
  name: 'localhost',
  production: false,
  //apiUrl: 'https://dev-api-backoffice.fidel.com.br/api/',
  // apiPagamento: 'https://dev-api-payment.fidel.com.br/api/',
  // apiUrl: 'https://api-backoffice.fidel.com.br/api/',
  // apiUrl: 'http://localhost:4000/api/',
  //apiPagamento: 'http://localhost:4001/api/',
  // apiUrl: 'https://dev-api-backoffice.fidel.com.br/api/',

  apiPagamento: 'https://payment.fidel.com.br/api/',
  apiUrl: 'https://api-backoffice.fidel.com.br/api/',
};
