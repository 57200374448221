import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  HttpErrorHandler,
  HandleError
} from '../../util/http-error-handler.service';
import { ResultViewModel } from '../../models/resultViewModel';
const API_URL = environment.apiUrl;
@Injectable()
export class MenuService {
  private handleError: HandleError;
  menuItems: Array<any>;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.menuItems = [];
    this.handleError = httpErrorHandler.createHandleError('MenuService');
  }

  carregarMenu(): Observable<any | ResultViewModel> {
    return this.http
      .get<ResultViewModel>(API_URL + '/menu')
      .pipe(catchError(this.handleError('carregarMenu', [])));
  }

  addMenu(
    items: Array<{
      text: string;
      heading?: boolean;
      link?: string; // internal route links
      elink?: string; // used only for external links
      target?: string; // anchor target="_blank|_self|_parent|_top|framename"
      icon?: string;
      alert?: string;
      submenu?: Array<any>;
    }>
  ) {
    items.forEach(item => {
      this.menuItems.push(item);
    });
  }

  getMenu() {
    return this.menuItems;
  }
}
