import { Component, OnInit, ViewChild } from '@angular/core';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;
import { Router } from '@angular/router';
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { AuthorizationService } from '../../routes/authorization/authorization.service';
import { LoadingService } from '../../util/loader.service';

const swal = require('sweetalert');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  logoutMenu = false;
  usuario: any;

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton') fsbutton; // the fullscreen button

  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    public auth: AuthorizationService,
    private router: Router,
    public loader: LoadingService
  ) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
   this.obterUsuarioLogado();
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    if (browser.msie) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  logout() {
    if (this.logoutMenu) {
      this.logoutMenu = false;
    } else {
      this.logoutMenu = true;
    }
  }

  toggleFullScreen(event) {

    if (screenfull.enabled) {
        screenfull.toggle();
    }
    // Switch icon indicator
    let el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
        el.children('em').removeClass('fa-expand').addClass('fa-compress');
    }
    else {
        el.children('em').removeClass('fa-compress').addClass('fa-expand');
    }
}

  exibeSobre() {
    swal({
      title: '',
      html: true,
      text: 'Versão 1.0<br>Data de lançamento 22/03/2020.', // Versão e data lançamento
      imageUrl: '../../assets/img/img_sobre.png',
      imageSize: '440x315'
    });
  }

  deslogar() {
    this.auth.LogoutBackOffice();
    this.loader.show('body');
    setTimeout(() => {
      this.loader.hide('body');
      this.router.navigate(['/login']);
    }, 1000);
  }

  obterUsuarioLogado() {

    let result = this.auth.GetLocalAuthorizationData();
    this.usuario = result;
  }

  onClickedOutside() {
    this.logoutMenu = false;
  }
}
