import {
  HandleError,
  HttpErrorHandler,
} from './../../../util/http-error-handler.service';
import { environment } from './../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private handleError: HandleError;
  constructor(private http: HttpClient, httpError: HttpErrorHandler) {
    this.handleError = httpError.createHandleError('DashboardService');
  }

  obterPieCharts(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/pieCharts')
      .pipe(catchError(this.handleError('pieCharts', [])));
  }

  obterResumo(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/resume')
      .pipe(catchError(this.handleError('resume', [])));
  }
  obterEmpresaPorEstado(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/statecompany')
      .pipe(catchError(this.handleError('statecompany', [])));
  }
  obterPontuacoesPorDiaDoMes(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalScoreByDayOfMonth')
      .pipe(catchError(this.handleError('totalScoreByDayOfMonth', [])));
  }
  obterPontuacoesPorAno(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalScoreByYearOfMonth')
      .pipe(catchError(this.handleError('totalScoreByYearOfMonth', [])));
  }
  obterComparacoesDePontuacoePorAno(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalScoreCompareYear')
      .pipe(catchError(this.handleError('totalScoreCompareYear', [])));
  }

  obterTopEmpresas(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/topCompany')
      .pipe(catchError(this.handleError('topCompany', [])));
  }

  obterComparacaoDeEmperesasCadastradaPorAno(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalRegisterCompareYear')
      .pipe(catchError(this.handleError('totalRegisterCompareYear', [])));
  }

  obterIndicadores(_param: any): Observable<any> {
    let params;
    if (_param != null) {
      params = new HttpParams()
        .set('CodigoEmpresa', `${_param.codigoEmpresa}`)
        .set('DataInicio', `${(_param.dataInicio ? _param.dataInicio.toJSON() : null)}`)
        .set('DataFinal', `${(_param.dataFim ? _param.dataFim.toJSON() : null)}`);
    }
    return this.http.get<any>(API_URL + 'dashboard/indicadores', { params })
      .pipe(catchError(this.handleError('obterIndicadores', []))
      );
  }


}
